[data-tooltip]:hover:after {
    z-index: 10;
}
[data-tooltip]:hover:before {
    z-index: 11;
}

.ui.menu {
    background-color: #aaa;
}

.ui.vertical.menu {
    background-color: #aaa;
}

.ui.inverted.menu,
.ui.inverted.segment {
    background-color: #666;
}

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover,
.ui.menu .active.item {
    background-color: #888;
}

.ui.inverted.menu .dropdown.item:hover,
.ui.inverted.menu .link.item:hover,
.ui.inverted.menu a.item:hover,
.ui.link.inverted.menu .item:hover {
    background: #444;
}

.ui.menu {
    margin: 0;
}

a.muted {
    color: rgba(255, 255, 255, 0.5);
}

a.muted:hover {
    color: white;
}
